<template>
  <section class="sign-in-page">
    <div id="container-inside ">
      <div id="circle-small"></div>
      <div id="circle-medium"></div>
      <div id="circle-large"></div>
      <div id="circle-xlarge"></div>
      <div id="circle-xxlarge"></div>
    </div>
    <div class="container p-0">
      <div class="row no-gutters">
        <!-- banner -->
        <div class="col-md-6 text-center">
          <div class="sign-in-detail text-white">
            <a class="sign-in-logo mb-2" href="#">
              <img src="@/assets/images/logo-full.png" class="img-fluid" alt="logo" />
            </a>
            <div class="swiper-area">
              <Swiper :options="options">
                <SwiperSlide class="item">
                  <img src="@/assets/images/login/1.png" class="img-fluid mb-1" alt="logo" />
                  <h4 class="mb-1 text-white">{{ $t('auth.first.title') }}</h4>
                  <p>{{ $t('auth.first.subTitle') }}</p>
                </SwiperSlide>
                <SwiperSlide class="item">
                  <img src="@/assets/images/login/2.png" class="img-fluid mb-1" alt="logo" />
                  <h4 class="mb-1 text-white">{{ $t('auth.second.title') }}</h4>
                  <p>{{ $t('auth.second.subTitle') }}</p>
                </SwiperSlide>
                <SwiperSlide class="item">
                  <img src="@/assets/images/login/3.png" class="img-fluid mb-1" alt="logo" />
                  <h4 class="mb-1 text-white">{{ $t('auth.third.title') }}</h4>
                  <p>{{ $t('auth.third.subTitle') }}</p>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>

        <!-- 登入表單 -->
        <div class="col-sm-6 bg-white pt-5 pb-5" style="margin: 0 auto;">
          <div class="sign-in-from">
            <h1 class="mb-0">{{ $t('label.signIn') }}</h1>
            <p>{{ $t('label.sigInSubText') }}</p>
            <form class="mt-4" @submit.prevent="onSubmit()">
              <div class="form-group">
                <label class="form-label" for="email">{{
                  $t('label.email')
                }}</label>
                <input type="email" v-model="form.email" class="form-control mb-0" id="email" name="email" :placeholder="$t('label.emailPlaceholder')" :class="{
                    'is-invalid':
                      v$.form.email.$dirty &&
                      !v$.form.email.$pending &&
                      v$.form.email.$invalid
                  }" />
                <span v-if="
                    v$.form.email.$dirty &&
                    !v$.form.email.$pending &&
                    v$.form.email.$invalid
                  " class="invalid-feedback">{{ $t('messages.required') }}</span>
              </div>
              <div class="form-group">
                <label class="form-label" for="exampleInputPassword1">{{
                  $t('label.password')
                }}</label>
                <router-link :to="{ name: 'auth1.recoverpassword1' }" class="float-end">
                  {{ $t('label.forgetPasswor') }}
                </router-link>
                <div class="input-group">
                  <input :type="showPassword ? 'text' : 'password'" v-model="form.password" id="password" name="password" class="form-control" :placeholder="$t('label.passwordPlaceholder')" :class="{
                      'is-invalid':
                        v$.form.password.$dirty &&
                        !v$.form.password.$pending &&
                        v$.form.password.$invalid
                    }" />
                  <span class="input-group-text">
                    <i @click="showPass" v-if="showPassword" class="fa fa-eye" id="togglePassword" style="cursor: pointer"></i>
                    <i @click="showPass" v-else class="fa fa-eye-slash" id="togglePassword" style="cursor: pointer"></i>
                  </span>
                  <span v-if="
                      v$.form.password.$dirty &&
                      !v$.form.password.$pending &&
                      v$.form.password.$invalid
                    " class="invalid-feedback">{{ $t('messages.required') }}</span>
                  <!-- <div class="input-group">
                    <button type="button" class="btn btn-circle-sm mx-1 my-1">
                      <i class="las fa-eye-slash me-0"></i>
                    </button>
                  </div> -->
                </div>
              </div>

              <div class="d-inline-block w-100">
                <!-- <div class="form-check d-inline-block mt-2 pt-1">
                  <input type="checkbox" class="form-check-input" id="customCheck11" />
                  <label class="form-check-label" for="customCheck11">
                    {{
                      $t('label.rememberMe')
                    }}
                  </label>
                </div> -->
                <button type="submit" class="btn btn-primary float-end">
                  <i v-show="commomData.btnLoader" class="fa fa-spinner fa-spin"></i>
                  {{ $t('label.signIn') }}
                </button>
              </div>
              <div class="sign-info">
                <span class="dark-color d-inline-block line-height-2">
                  {{ $t('label.signUpText') }}
                  <router-link :to="{ name: 'auth1.sign-up' }">{{
                    $t('label.signUp')
                  }}</router-link>
                </span>

                <ul class="iq-social-media">
                  <!-- <li>
                    <a href="#"><i class="ri-google-box-line"></i></a>
                  </li>-->
                  <!-- <li>
                    <a href="#">
                      <i class="ri-google-line"></i>
                    </a>
                  </li> -->
                  <!-- <li>
                    <a href="#"><i class="ri-instagram-line"></i></a>
                  </li>-->
                </ul>
                <span class="d-flex">
                  <span class="dark-color d-inline-block line-height-2">
                    {{ $t('label.accountNotEnabled') }}
                    <router-link :to="{ name: 'auth1.vaildemail' }">{{ $t('label.requestVerificationLetter') }}
                    </router-link>
                  </span>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper from '../../../components/custom/slider/Swiper'
import SwiperSlide from '../../../components/custom/slider/SwiperSlide'
import { signIn } from '@/api/auth'
import useVuelidate from '@vuelidate/core'
import { required, minLength, email } from '@vuelidate/validators'
import { mapGetters } from 'vuex'
import {
  setLocalStorageClient,
  setLocalStorageClientToString
} from '@/utils/assist'
import { commomData } from '@/utils/commonVmodel'
export default {
  name: 'SignIn',
  components: { Swiper, SwiperSlide },
  setup() {
    const v$ = useVuelidate()
    return { v$ }
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState',
      shopUrl: 'Setting/shopUrl',
    })
  },
  data() {
    return {
      showPassword: false,
      commomData: commomData(),
      options: {
        centeredSlides: false,
        loop: false,
        slidesPerView: 1,
        autoplay: {
          delay: 3000
        },
        spaceBetween: 15,
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        // And if we need scrollbar
        scrollbar: {
          el: '.swiper-scrollbar'
        }
      },
      form: {
        // email: 'creator.app-858588574@snapmail.cc',
        // password: '123456',
        email: '',
        password: '',
        platform: 1
      }
    }
  },
  validations() {
    return {
      form: {
        email: { required, email },
        password: { required, minLength: minLength(5) }
      }
    }
  },
  methods: {
    showPass() {
      this.showPassword = !this.showPassword
    },
    async onSubmit() {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }
      this.commomData.btnLoader = true
      signIn(this.form)
        .then(res => {
          console.log(res)
          let data = {
            user: res.user,
            token: res.token
          }
          setLocalStorageClient('user', data.user)
          setLocalStorageClientToString('token', data.token)
          this.$store.dispatch('User/addTokenAction', data.token)
          this.$store.dispatch('User/addUserAction', data.user)
          // this.$router.push({ name: 'auth1.promote' })
          if (this.shopUrl == '') {
            this.$router.push({ name: 'social.list' })
          } else {
            this.$router.push(this.shopUrl)
          }
          this.$store.dispatch('Setting/setShopUrlAction', '');
        })
        .catch(err => {
          this.commomData.btnLoader = false
          console.log(err)
          this.$swal.mixin().fire({
            icon: 'error',
            text: err.response.data.message
          })
        })
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.swiper-area {
  overflow-x: hidden;
}
</style>
